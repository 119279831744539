import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { variantPages } from "../animations/pageTransition";
import React from "react";
import MapRoute from "../components/mapRoute";
import Feedback from "../components/service/feedback";
import MetaTag from "../components/metaTag";
import Footer from "../components/footer";
import cx from "classnames";
import { Directions } from "../components/svg/services";
import {
  IoLocationOutline,
  IoTimeOutline,
  IoDocumentTextOutline,
} from "react-icons/io5";
import { FaTruck } from "react-icons/fa";
import { carcarrierSEO } from "../data/seo";
import * as styles from "../styles/pages/services.module.scss";

function Carcarrier() {
  return (
    <motion.main
      variants={variantPages}
      initial="initial"
      exit="exit"
      animate="animate"
    >
      <MetaTag data={carcarrierSEO} />
      <section className={styles.container}>
        <div className={styles.title}>
          <h1>Автовоз</h1>
        </div>

        <div className={styles.description}>
          <div className={styles.header}>
            <h3>Полноценный автовоз на 7-8 автомобилей</h3>
            <p className={styles.duration}>от 2 до 10 рабочих дней</p>
          </div>
          <div className={styles.content}>
            <div className={styles.preview}>
              <StaticImage
                src="../images/services/2.jpeg"
                alt="Перевозка автомобиля автовозом"
              />
            </div>
            <div className={styles.info}>
              <p>
                Транспортировка на автовозе – выгодный способ доставить
                автомобиль в любую точку страны. Компания «Автовоз Логистик» –
                является надёжным партнёром для сотни автовладельцев, частных
                дилеров и автосалонов по всей России, которые пользуются
                доставкой транспорта автовозом. Наши перевозки качественные,
                доступные и максимально быстрые. Автовоз подбирается с учетом
                особенностей машины и ваших требований. Мы готовы перевезти
                любое транспортное средство: легковой, грузовой автомобиль,
                автобус, мотоцикл или сразу несколько видов техники.
                Классический автовоз рассчитан на 8 автомобилей класса «B»:
                Volkswagen Polo, Kia Rio, Škoda Rapid, Hyundai Solaris, Lada
                Vesta, Renault Logan и др.
              </p>
            </div>
          </div>
        </div>
        <MapRoute />

        <div className={styles.about}>
          <div className={styles.block}>
            <h3>Преимущества</h3>
            <ul className={styles.list}>
              <li>
                <span>Экономия.</span> Заказав автовоз, вы не платите за топливо
                и ночлег во время дальней поездки
              </li>
              <li>
                <span>Скорость.</span> Автовоз за сутки проходит в среднем около
                500 км
              </li>
              <li>
                <span>Безопасность.</span> Не каждый автомобиль может преодолеть
                многокилометровое расстояние без последствий. Отправляя машину
                автовозом, вероятность повреждений сводится к нулю
              </li>
              <li>
                <span>Надежность.</span> Автовозы соответствуют всем требованиям
                безопасной перевозки автомобилей. Крепление автомобилей на
                платформах при помощи специальных ремней и откатников
              </li>
              <li>
                <span>Комфорт.</span> Опытные водители имеют стаж вождения более
                7 лет
              </li>
              <li>
                Круглосуточная <span>связь с диспетчером</span> и контроль
                маршрута автовоза
              </li>
            </ul>
            <div className={styles.hint}>
              <FaTruck />
            </div>
          </div>

          <div className={styles.block}>
            <h3>Правила</h3>
            <p className={styles.text}>
              <span>
                В обязанности водителей выходит регулярный осмотр автомобилей в
                пути.
              </span>
              <br />
              <br />
              Существует профессиональное правило «кулак и локоть». Расстояние
              от поверхности кузова автомобиля до любой другой поверхности –
              платформы автовоза или соседнего автомобиля – не должно быть менее
              кулака, 8-10 см. А локоть, 40-50 см., это минимальное расстояние
              между поверхностями двух автомобилей на тягаче и прицепе.
            </p>
            <Directions className={styles.background} />
            <div className={styles.hint}>
              <IoLocationOutline />
            </div>
          </div>

          <div className={styles.block}>
            <h3>Формирование сроков и цены</h3>
            <p className={styles.text}>
              <span>Как складывается цена:</span>
              <br />
              <br />
            </p>
            <ul className={styles.list}>
              <li>
                Габариты автомобиля. Все а/м мы распределили на несколько
                классов. Градация связана с возможностями автовоза
              </li>
              <li>Маршрут перевозки</li>
              <li>Доп. услуги доставки: доставка от «двери до двери»</li>
              <li>дополнительное страхование груза</li>
              <li>погрузка / выгрузка аварийных или страховых автомобилей</li>
            </ul>
            <p className={styles.text}>
              <br />
              <span>
                Сроки:
                <br />В среднем перевозка занимает от 3 до 10 рабочих дней в
                зависимости от направлений и сезонного фактора.
              </span>
              <br />
              <br />
              Дополнительное время требуется на приемку и оформление
              сопроводительных документов и обработку груза по факту завершения
              перевозки
            </p>
            <div className={styles.hint}>
              <IoTimeOutline />
            </div>
          </div>

          <div className={styles.block}>
            <h3>Документы для перевозки</h3>
            <ul className={styles.list}>
              <li>Копии паспорта отправителя или заказчика</li>
              <li>
                Реквизитная карточка юридического лица и доверенность на
                представителя или печать организации
              </li>
              <li>
                Копия документа на автомобиль (ПТС или свидетельство о
                регистрации)
              </li>
              <li>
                Контактные данные отправителя и получателя (ФИО и телефоны для
                связи)
              </li>
              <li>Маршрут доставки</li>
              <li>Примерная рыночная стоимость автомобиля</li>
              <li>
                Адрес погрузки и выгрузки, в случае если автомобиль доставляется
                с адреса в адрес
              </li>
            </ul>
            <Directions className={styles.background} />
            <div className={styles.hint}>
              <IoDocumentTextOutline />
            </div>
          </div>

          <div className={cx(styles.block, styles.additional)}>
            <p className={styles.text}>
              Цена на аренду автовоза всегда индивидуальна. Звоните и получите
              полный комплект услуг: от минимальной стоимости доставки автовозом
              до полного контроля всех этапов транспортировки.
              <br />
              <br />
              При доставке автовозом ваша машина находится под контролем опытных
              специалистов компании «Автовоз Логистик», которые берут на себя
              ответственность за её сохранность.
            </p>
          </div>
        </div>

        <Feedback />
      </section>
      <Footer />
    </motion.main>
  );
}

export default Carcarrier;
